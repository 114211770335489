import React, { useState, useEffect, useRef } from 'react';
import { ReactTyped } from 'react-typed';
import './App.css';

const translations = {
  en: {
    market: "Market",
    learn: "Learn",
    wallet: "Wallet",
    exchange: "Exchange",
    headline1: "Embrace the Future of Finance",
    headline2: "Secure Your Digital Assets",
    headline3: "Invest in Bitcoin with Confidence",
    subheadline: "DigitalGold paves the way into the decentralized economy.\nWe make it easy to input your most treasured financial goals.",
    priceHistory: "Bitcoin price history",
    wallets: "Wallets",
    hotWallets: "Hot wallets",
    coldWallets: "Cold wallets",
    hotWalletDesc: "Software wallets connected to the internet. Easy to use but less secure.",
    coldWalletDesc: "Hardware wallets stored offline. More secure but less convenient.",
    exchanges: "Exchanges",
    exchangeDesc: "Platforms to buy, sell, and trade cryptocurrencies.",
    otcDesc: "OTC trading allows for large-volume transactions outside of traditional exchanges, offering privacy and potentially better prices for big trades.",
    otcComingSoon: "Specific OTC options coming soon...",
    topBooks: "Top 3 books on Bitcoin",
    newsletterSignup: "Newsletter signup",
    emailPlaceholder: "Enter your email",
    subscribe: "Subscribe",
    comingSoon: "Coming soon! Sign up to get notified.",
    footer: "© 2024 DigitalGold. All rights reserved.",
    disclaimer: "Disclaimer: The information provided on this site is for educational purposes only and should not be considered financial advice. We are not liable for any financial decisions made based on this information.",
    currency: "USD"
  },
  de: {
    market: "Markt",
    learn: "Lernen",
    wallet: "Wallet",
    exchange: "Börse",
    headline1: "Gestalten Sie die Zukunft der Finanzen",
    headline2: "Sichern Sie Ihre digitalen Vermögenswerte",
    headline3: "Investieren Sie mit Vertrauen in Bitcoin",
    subheadline: "DigitalGold ebnet den Weg in die dezentralisierte Wirtschaft.\nWir machen es Ihnen leicht, Ihre wertvollsten finanziellen Ziele einzugeben.",
    priceHistory: "Bitcoin-Preisverlauf",
    wallets: "Wallets",
    hotWallets: "Hot Wallets",
    coldWallets: "Cold Wallets",
    hotWalletDesc: "Software-Wallets mit Internetverbindung. Einfach zu bedienen, aber weniger sicher.",
    coldWalletDesc: "Hardware-Wallets offline gespeichert. Sicherer, aber weniger bequem.",
    exchanges: "Börsen",
    exchangeDesc: "Plattformen zum Kaufen, Verkaufen und Handeln von Kryptowährungen.",
    otcDesc: "OTC-Handel ermöglicht große Transaktionen außerhalb traditioneller Börsen und bietet Privatsphäre und potenziell bessere Preise für große Trades.",
    otcComingSoon: "Spezifische OTC-Optionen kommen bald...",
    topBooks: "Top 3 Bücher über Bitcoin",
    newsletterSignup: "Newsletter-Anmeldung",
    emailPlaceholder: "E-Mail-Adresse eingeben",
    subscribe: "Abonnieren",
    comingSoon: "Demnächst verfügbar! Melden Sie sich an, um benachrichtigt zu werden.",
    footer: "© 2024 DigitalGold. Alle Rechte vorbehalten.",
    disclaimer: "Haftungsausschluss: Die auf dieser Website bereitgestellten Informationen dienen ausschließlich Bildungszwecken und sollten nicht als Finanzberatung betrachtet werden. Wir haften nicht für finanzielle Entscheidungen, die auf Grundlage dieser Informationen getroffen werden.",
    currency: "EUR"
  }
};

const books = [
  {
    title: "The Bitcoin Standard",
    author: "Saifedean Ammous",
    image: "https://images-na.ssl-images-amazon.com/images/I/51WQo3g5xvL._SX330_BO1,204,203,200_.jpg",
    summary: "Explores the historical context of money, arguing for Bitcoin's role as digital gold and a potential global monetary standard. Key points include the concept of hard money, Bitcoin's fixed supply, and its potential to resist government manipulation."
  },
  {
    title: "Mastering Bitcoin",
    author: "Andreas M. Antonopoulos",
    image: "https://images-na.ssl-images-amazon.com/images/I/51E2055ZGUL._SX379_BO1,204,203,200_.jpg",
    summary: "A technical deep dive into Bitcoin's operation, ideal for developers and technical enthusiasts. Covers the intricacies of the blockchain, transaction processes, and the underlying cryptography that makes Bitcoin secure and decentralized."
  },
  {
    title: "Blockchain Revolution",
    author: "Don Tapscott & Alex Tapscott",
    image: "https://images-na.ssl-images-amazon.com/images/I/51prGPccOlL._SX329_BO1,204,203,200_.jpg",
    summary: "Explores how blockchain technology is revolutionizing business, government, and society. The authors delve into the potential applications of blockchain beyond cryptocurrencies, discussing its impact on various industries and its role in shaping the future of the internet."
  }
];

const exchanges = [
  {
    name: "Coinbase",
    description: "User-friendly platform for buying, selling, and storing cryptocurrency.",
    logo: "/path_to_coinbase_logo.png",
    url: "https://www.coinbase.com"
  },
  {
    name: "Binance",
    description: "Large cryptocurrency exchange offering a wide range of trading pairs and features.",
    logo: "/path_to_binance_logo.png",
    url: "https://www.binance.com"
  },
  {
    name: "BingX",
    description: "Innovative social trading platform for cryptocurrencies.",
    logo: "/path_to_bingx_logo.png",
    url: "https://www.bingx.com"
  }
];

const wallets = {
  hot: [
    {
      name: "Electrum",
      description: "Lightweight, feature-rich Bitcoin wallet with advanced security options.",
      logo: "/path_to_electrum_logo.png",
      url: "https://electrum.org/"
    },
    {
      name: "Exodus",
      description: "User-friendly multi-cryptocurrency wallet with built-in exchange.",
      logo: "/path_to_exodus_logo.png",
      url: "https://www.exodus.com/"
    },
    {
      name: "Mycelium",
      description: "Mobile Bitcoin wallet with advanced privacy features.",
      logo: "/path_to_mycelium_logo.png",
      url: "https://wallet.mycelium.com/"
    }
  ],
  cold: [
    {
      name: "Ledger Nano X",
      description: "Hardware wallet supporting a wide range of cryptocurrencies with Bluetooth connectivity.",
      logo: "/path_to_ledger_logo.png",
      url: "https://www.ledger.com/"
    },
    {
      name: "Trezor Model T",
      description: "Advanced hardware wallet with a touchscreen interface for enhanced security.",
      logo: "/path_to_trezor_logo.png",
      url: "https://trezor.io/"
    },
    {
      name: "ColdCard Mk4",
      description: "Bitcoin-only hardware wallet designed for maximum security and air-gapped operations.",
      logo: "/path_to_coldcard_logo.png",
      url: "https://coldcard.com/"
    }
  ]
};

const TradingViewChart = ({ isDarkMode, language }) => {
  const container = useRef();

  useEffect(() => {
    const script = document.createElement('script');
    const currencyPair = translations[language]?.currency ? `BTC${translations[language].currency}` : 'BTCUSD';
    script.innerHTML = `
      new TradingView.widget({
        "width": "100%",
        "height": 400,
        "symbol": "COINBASE:${currencyPair}",
        "interval": "5",
        "timezone": "Etc/UTC",
        "theme": "${isDarkMode ? 'dark' : 'light'}",
        "style": "1",
        "locale": "${language}",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "allow_symbol_change": true,
        "container_id": "tradingview_chart"
      });
    `;
    container.current.innerHTML = '';
    container.current.appendChild(script);
  }, [isDarkMode, language]);

  return <div id="tradingview_chart" ref={container}></div>;
};

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [language, setLanguage] = useState('en');

  const toggleTheme = () => setIsDarkMode(!isDarkMode);
  const toggleLanguage = () => setLanguage(language === 'en' ? 'de' : 'en');
  const t = translations[language];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900'} font-sans transition-colors duration-300`}>
      <header className="p-4 flex justify-between items-center bg-bitcoin sticky top-0 z-10">
        <h1 className="text-2xl font-bold text-white font-montserrat">
          Digital <span className="ml-2">Gold</span>
        </h1>
        <nav className="flex items-center">
          <ul className="flex space-x-4 text-white mr-4">
            <li className="cursor-pointer hover:underline" onClick={() => scrollToSection('market')}>{t.market}</li>
            <li className="cursor-pointer hover:underline" onClick={() => scrollToSection('exchange')}>{t.exchange}</li>
            <li className="cursor-pointer hover:underline" onClick={() => scrollToSection('wallet')}>{t.wallet}</li>
            <li className="cursor-pointer hover:underline" onClick={() => scrollToSection('books')}>{t.learn}</li>
          </ul>
          <button onClick={toggleTheme} className="p-2 rounded-full bg-white text-bitcoin mr-2 hover:bg-gray-200">
            {isDarkMode ? 'Light' : 'Dark'}
          </button>
          <button onClick={toggleLanguage} className="p-2 rounded-full bg-white text-bitcoin hover:bg-gray-200">
            {language === 'en' ? 'DE' : 'EN'}
          </button>
        </nav>
      </header>

      <main className="p-8">
        <div className="mb-12">
          <h2 className="text-5xl md:text-7xl font-extrabold leading-tight text-bitcoin">
            <ReactTyped
              strings={[t.headline1, t.headline2, t.headline3]}
              typeSpeed={40}
              startDelay={1000}
              backDelay={3000}
              backSpeed={10}
              loop
              smartBackspace={false}
              showCursor={true}
              cursorChar="|"
            />
          </h2>
          <p className={`text-xl md:text-2xl mt-4 max-w-2xl whitespace-pre-line ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            {t.subheadline}
          </p>
        </div>

        <div id="market" className="mb-12">
          <h3 className="text-3xl font-bold mb-4 text-bitcoin">{t.priceHistory}</h3>
          <TradingViewChart isDarkMode={isDarkMode} language={language} />
        </div>

        <p className="text-lg mb-8">
          Start your journey into the world of Bitcoin by understanding how to buy and trade. Exchanges are your gateway to acquiring Bitcoin.
        </p>

        <div id="exchange" className="mb-12">
          <h3 className="text-3xl font-bold mb-4 text-bitcoin">{t.exchanges}</h3>
          <p className="mb-4">{t.exchangeDesc}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {exchanges.map((exchange, index) => (
              <div key={index} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
                <img src={exchange.logo} alt={`${exchange.name} logo`} className="h-12 mb-4" />
                <h4 className="text-xl font-bold mb-2 text-bitcoin">{exchange.name}</h4>
                <p className="mb-4">{exchange.description}</p>
                <a href={exchange.url} target="_blank" rel="noopener noreferrer" className="text-bitcoin hover:underline">
                  Visit {exchange.name}
                </a>
              </div>
            ))}
          </div>
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg mt-8`}>
            <h4 className="text-xl font-bold mb-2 text-bitcoin">Over-the-Counter (OTC)</h4>
            <p>{t.otcDesc}</p>
            <p className="mt-4">{t.otcComingSoon}</p>
          </div>
        </div>

        <p className="text-lg my-8">
          Now that you know how to buy Bitcoin, it's crucial to learn how to store it securely. Let's explore different types of wallets.
        </p>

        <div id="wallet" className="mb-12">
          <h3 className="text-3xl font-bold mb-4 text-bitcoin">{t.wallets}</h3>
          <div className="mb-8">
            <h4 className="text-2xl font-bold mb-4 text-bitcoin">{t.hotWallets}</h4>
            <p className="mb-4">{t.hotWalletDesc}</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {wallets.hot.map((wallet, index) => (
                <div key={index} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
                  <img src={wallet.logo} alt={`${wallet.name} logo`} className="h-12 mb-4" />
                  <h4 className="text-xl font-bold mb-2 text-bitcoin">{wallet.name}</h4>
                  <p className="mb-4">{wallet.description}</p>
                  <a href={wallet.url} target="_blank" rel="noopener noreferrer" className="text-bitcoin hover:underline">
                    Learn more about {wallet.name}
                  </a>
                </div>
              ))}
           </div>
          </div>
          <div>
            <h4 className="text-2xl font-bold mb-4 text-bitcoin">{t.coldWallets}</h4>
            <p className="mb-4">{t.coldWalletDesc}</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {wallets.cold.map((wallet, index) => (
                <div key={index} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
                  <img src={wallet.logo} alt={`${wallet.name} logo`} className="h-12 mb-4" />
                  <h4 className="text-xl font-bold mb-2 text-bitcoin">{wallet.name}</h4>
                  <p className="mb-4">{wallet.description}</p>
                  <a href={wallet.url} target="_blank" rel="noopener noreferrer" className="text-bitcoin hover:underline">
                    Learn more about {wallet.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>

        <p className="text-lg my-8">
          To deepen your understanding of Bitcoin and its potential, consider these essential reads from industry experts.
        </p>

        <div id="books" className="mb-12">
          <h3 className="text-3xl font-bold mb-4 text-bitcoin">{t.topBooks}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {books.map((book, index) => (
              <div key={index} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg flex flex-col items-center`}>
                <img src={book.image} alt={book.title} className="w-32 h-48 object-cover mb-4" />
                <h4 className="text-xl font-bold mb-2">{book.title}</h4>
                <p className="text-sm mb-2">by {book.author}</p>
                <p className="text-sm text-center">{book.summary}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg mb-12`}>
          <h3 className="text-2xl font-bold mb-4 text-bitcoin">{t.newsletterSignup}</h3>
          <p className="mb-4">{t.comingSoon}</p>
          <form className="flex flex-col md:flex-row gap-4">
            <input 
              type="email" 
              placeholder={t.emailPlaceholder}
              className={`flex-grow p-2 rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'}`}
            />
            <button 
              type="submit" 
              className="bg-bitcoin text-white font-bold py-2 px-4 rounded hover:bg-opacity-90"
            >
              {t.subscribe}
            </button>
          </form>
        </div>
      </main>

      <footer className="mt-12 p-4 text-center">
        <p>{t.footer}</p>
        <p className="mt-2 text-sm">{t.disclaimer}</p>
      </footer>
    </div>
  );
}

export default App;